import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from 'components';

interface DisclaimerProps {
  bgColor?: string;
  text: string;
}
const Container = styled.div<{ bgColor?: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1.25rem 1rem 3rem 1rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 21.4375rem;
`;

const DisclaimerText = styled(Text)`
  color: #4d4d4d;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.00825rem;
`;

const Disclaimer: FC<DisclaimerProps> = ({ bgColor, text }) => {
  return (
    <Container bgColor={bgColor}>
      <ContentContainer>
        <DisclaimerText>{text}</DisclaimerText>
      </ContentContainer>
    </Container>
  );
};

export default Disclaimer;
